import { RES_TYPES, useFetch } from '@chainless/chainless-react';
import './vgm.css';
import { useEffect, useState } from 'react';
import { DeleteVGMItem } from './DeleteVGMItem';

export const Telefoonnummers = ({ telefoonnummers, refresh }) => {
  const [title, setTitle] = useState('');
  const [nummer, setNummer] = useState('');
  const [address, setAddress] = useState('');
  const { res: postResRes, fetchData, error: postErr } = useFetch(`/api/vgm/telefoonnummers`, { method: 'POST', resType: RES_TYPES.text, lateFetch: true });

  const postTelfoonnummer = () => {
    if (title && nummer) {
      fetchData(JSON.stringify({
        title: title,
        phone: nummer,
        address: address
      }));
    }
  };

  useEffect(() => {
    if (postResRes && postResRes.ok) {
      refresh();
      setTitle('');
      setNummer('');
      setAddress('');
    } else if (postErr) {
      alert('Fout bij het updaten van een nieuws item', postErr);
    }
  }, [postResRes, postErr, refresh]);

  const fillInPhoneNumber = (e) => {
    const inputText = e.target.value;
    const onlyNumbers = inputText.replace(/\D/g, '');
    setNummer(onlyNumbers);
  }

  return (
    <div className="vgmDetails">
      <h2>Telefoonnummers</h2>
      <div className="documenten-lijst">
        {telefoonnummers && telefoonnummers.map(tel => (
          <div className="detailsLijstItem" key={tel.id}>
            <p>{tel.title} - {tel.phone} - {tel.address}</p>
            <DeleteVGMItem id={tel.id} refresh={refresh} choice={"telefoonnummers"} />
          </div>
        ))}
      </div>

      <div className='telefoonnummerForm'>
        <h2>Gegevens uploaden</h2>
        <div className='telefoonInputs'>
          <p>Titel</p>
          <input className='vgm-input' type='text' value={title} onChange={e => setTitle(e.target.value)} placeholder='------' data-testid="titel"/>
        </div>
        <div className='telefoonInputs'>
          <p>Telefoonnummer</p>
          <input className='vgm-input' type='text' value={nummer} onInput={fillInPhoneNumber} placeholder='06 / 0800 / 0594' maxLength={10} data-testid="tel"/>
        </div>
        <div className='telefoonInputs'>
          <p>Adres</p>
          <input className='vgm-input' type='text' value={address} onChange={e => setAddress(e.target.value)} placeholder='Molenstraat 126-C, 9402 JS Assen' data-testid="adres"/>
        </div>
        <button onClick={() => postTelfoonnummer()}>Toevoegen</button>
      </div>
    </div>
  );
};