import { RES_TYPES, useFetch } from '@chainless/chainless-react';
import './vgm.css';
import { useEffect, useState } from 'react';
import { DeleteVGMItem } from './DeleteVGMItem';

export const KernwaardenDetails = ({ kernwaarden, refresh }) => {
  const [kernwaarde, setKernwaarde] = useState('');
  const { res: postResRes, fetchData, error: postErr } = useFetch(`/api/vgm/kernwaarden`, { method: 'POST', resType: RES_TYPES.text, lateFetch: true });

  const postKernwaarde = () => {
    if (kernwaarde) {
      fetchData(JSON.stringify({
        text: kernwaarde
      }));
    }
  };

  useEffect(() => {
    if (postResRes && postResRes.ok) {
      refresh();
      setKernwaarde('');
    } else if (postErr) {
      alert('Fout bij het updaten van een nieuws item', postErr);
    }
  }, [postResRes, postErr, refresh]);

  return (
    <div className="vgmDetails">
      <h2>Kernwaarden</h2>
      <div className="documenten-lijst">
        {kernwaarden && kernwaarden.map(waarde => (
          <div className="detailsLijstItem" key={waarde.id}>
            <p>{waarde.text}</p>
            <DeleteVGMItem id={waarde.id} refresh={refresh} choice={"kernwaarden"} />
          </div>
        ))}
      </div>

      <div>
        <input className='vgm-input' type='text' value={kernwaarde} onChange={e => setKernwaarde(e.target.value)} />
        <button onClick={() => postKernwaarde()}>Toevoegen</button>
      </div>
    </div>
  );
};