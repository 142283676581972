import { ChnSortSearchTable, useFetch } from "@chainless/chainless-react";
import { useEffect, useState } from "react";
import { ApproveVerlof } from "./ApproveVerlof";

export const VerlofList = ({ user }) => {
  const [page, setPage] = useState(0);
  const [verlofList, setVerlofList] = useState([]);
  const { data: verlof, error, loading, fetchData } = useFetch(`/api/verlof?page=${page}`);

  const refreshAll = () => {
    setVerlofList([]);
    setPage(0);
    fetchData();
  };

  const loadMoreVerlof = async () => {
    setPage(page + 1);
    await fetchData();
  };

  useEffect(() => {
    if (verlof && verlof.length > 0) {
      const uniqueVerlof = verlof.filter((newVerlof) => !verlofList.find((existingVerlof) => existingVerlof.id === newVerlof.id));
      setVerlofList((prevVerlof) => [...prevVerlof, ...uniqueVerlof]);
    }
  }, [verlof]);

  const headers = [
    { key: 'aanvrager', name: 'Aanvrager' },
    { key: 'datumAanvraag', name: 'Datum aanvraag', valueMapper: (datum) => new Date(datum * 1000).toLocaleDateString('nl-NL') },
    { key: 'omschrijving', name: 'Omschrijving' },
    { key: 'start', name: 'Start verlof', valueMapper: (datum) => new Date(datum * 1000).toLocaleDateString('nl-NL'), search: false },
    { key: 'eind', name: 'Eind verlof', valueMapper: (datum) => new Date(datum * 1000).toLocaleDateString('nl-NL'), search: false },
    { key: 'uren', name: 'Uren' },
    { key: 'goedgekeurdDoor', name: 'Goedkeurder' },
    { key: 'datumGoedgekeurd', name: 'Datum goedgekeurd', valueMapper: (datum) => datum ? new Date(datum * 1000).toLocaleDateString('nl-NL') : null, search: false },
    { key: 'statusAanvraag', name: 'Status' },
    { key: 'id', name: 'Change', valueMapper: (id) => <ApproveVerlof fetch={refreshAll} id={id} goedkeurder={user} refresh={refreshAll} item={'verlof'} />, search: false }
  ];

  return (
    <>
      {loading && <p>Verlof ophalen...</p>}
      {error && <p>Error bij ophalen verlof: {error}</p>}
      {verlof && <div className='table'><ChnSortSearchTable headers={headers} data={verlofList} /><button onClick={() => loadMoreVerlof()}>Meer Verlof Laden</button></div>}
    </>
  );
};