import { useFetch } from "@chainless/chainless-react";
import { VerlofList } from "../components/VerlofList";

export const VerlofPage = () => {
  const { data: user } = useFetch('/api/graph/user');

  return (
    <div className="content">
      <h1>Verlof</h1>
      {user &&
        <VerlofList user={user.id} />
      }
    </div>
  );
};