import { useEffect } from "react";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { BarChart } from "./BarChart";

export const MeldingCharts = ({ data }) => {
  const [toProjectsData, setToProjectsData] = useState(true);
  const [filteredData, setFilteredData] = useState();
  const [meldingenData, setMeldingenData] = useState();
  const [projData, setProjData] = useState();
  const [totalThisYear, setTotalThisYear] = useState();

  useEffect(() => {
    if (data) {
      const groupedData = data.reduce((acc, item) => {
        const timestampYear = new Date(item.timestamp * 1000).getFullYear();
        if (!acc[timestampYear]) {
          acc[timestampYear] = [];
        }
        acc[timestampYear].push(item);
        return acc;
      }, []);

      const filteredDataArray = Object.values(groupedData);
      setFilteredData(filteredDataArray);
      setTotalThisYear(filteredDataArray[filteredDataArray.length - 1].length);
    }
  }, [data]);

  useEffect(() => {
    if (filteredData) {
      const meldingenDataPerJaar = {};
      const years = new Set();
      const priorities = new Set();

      filteredData.forEach(yearData => {
        yearData.forEach(item => {
          const timestampYear = new Date(item.timestamp * 1000).getFullYear();
          years.add(timestampYear);
          if (!meldingenDataPerJaar[timestampYear]) {
            meldingenDataPerJaar[timestampYear] = { PRIO_1: 0, PRIO_2: 0, PRIO_3: 0 };
          }
          if (item.prioriteit && meldingenDataPerJaar[timestampYear][item.prioriteit] >= 0) {
            meldingenDataPerJaar[timestampYear][item.prioriteit]++;
            priorities.add(item.prioriteit);
          }
        });
      });

      const uniqueYears = Array.from(years);
      const uniquePriorities = Array.from(priorities);

      const colors = ['#485074', '#9c9c9c', '#00a0bf', '#006adf'];
      const meldingenData = uniquePriorities.map((priority, index) => {
        const yValues = uniqueYears.map(year => meldingenDataPerJaar[year][priority] || 0);
        return {
          name: priority,
          type: 'bar',
          width: 0.6,
          x: uniqueYears,
          y: yValues,
          text: yValues.map(String),
          hovertemplate: '<b>%{fullData.name}</b><br><b>%{x}</b>: %{y}<extra></extra>',
          marker: {
            color: colors[index],
            opacity: 0.8,
          }
        };
      });

      setMeldingenData(meldingenData);
    }
  }, [filteredData]);

  useEffect(() => {
    if (filteredData) {
      const projCountsPerYear = {};

      filteredData.forEach(yearData => {
        yearData.forEach(item => {
          const { timestamp, project } = item;
          const year = new Date(timestamp * 1000).getFullYear();
          if (!projCountsPerYear[year]) {
            projCountsPerYear[year] = {};
          }
          if (!projCountsPerYear[year][project]) {
            projCountsPerYear[year][project] = 0;
          }
          projCountsPerYear[year][project]++;
        });
      });

      const years = Object.keys(projCountsPerYear);
      const projects = Array.from(new Set(Object.values(projCountsPerYear).flatMap(Object.keys)));
      const colors = [
        '#32a852', '#329ea8', '#3253a8', '#22855a', '#5a2285',
        '#b37524', '#aeb324', '#71bbbf', '#9845a8', '#d1b8d6'
      ];

      const projChartData = projects.map((project, index) => ({
        name: project !== 'null' ? project.length > 18 ? project.slice(1, 18) : project : 'Zonder project',
        type: 'bar',
        width: 0.6,
        x: years.map(year => year),
        y: years.map(year => projCountsPerYear[year][project] || 0),
        text: years.map(year => (projCountsPerYear[year][project] || 0).toString()),
        hovertemplate: '<b>%{fullData.name}</b><br><b>%{x}</b>: %{y}<extra></extra>',
        marker: {
          color: colors[index % colors.length],
          opacity: 0.8,
        }
      }));

      setProjData(projChartData);
    }
  }, [filteredData]);

  return (
    <div className="details" data-testid="bar-chart-total">
      {toProjectsData ?
        <BarChart selectedData={meldingenData} total={totalThisYear} barmode={'relative'} title={'Melding voorval per jaar'} />
        :
        <BarChart selectedData={projData} total={totalThisYear} barmode={'relative'} title={'Melding voorval per project per jaar'} />
      }
      <div className="yearButtons">
        <FaEye onClick={() => setToProjectsData(!toProjectsData)} className="arrow-icon" />
      </div>
    </div>
  );
};