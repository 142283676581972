import { RES_TYPES, useFetch } from "@chainless/chainless-react";
import { useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import "./ImagePopup.css";

export const DeleteNewsItem = ({ id, refresh }) => {
  const { data, error, fetchData } = useFetch(`api/news/${id}`, { method: 'DELETE', resType: RES_TYPES.text, lateFetch: true  });

  const handleDelete = () => {
    const userConfirmation = window.confirm('Weet u het zeker?');
    if (userConfirmation) {
      fetchData();
    }
  };

  useEffect(() => {
    if (data) {
      refresh();
    } else if (error) {
      alert('Fout bij het verwijderen van een nieuws item', error);
    }
  });

  if (id) {
    return (
      <>
        <FaTrash onClick={handleDelete} className="update-icon"/>
      </>
    );
  }
};
