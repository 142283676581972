import { FaEye, FaPencilAlt } from "react-icons/fa";
import "./ImagePopup.css";
import { DeleteNewsItem } from "./DeleteNewsItem";
import { useState } from "react";
import { CreateNewsItem } from "./CreateNewsItem";
import { UpdateMelding } from "./UpdateMelding";

export const UpdateItem = ({ id, refresh, item, selectedId, setSelectedId }) => {
  const [update, setUpdate] = useState(false);

  const toggleItem = () => setUpdate(s => !s);

  const updateContainer = !update ? '' : (
    <div className="foto-container">
      <div className="update-content">
        {item === 'news' ?
          <CreateNewsItem id={id} setUpdate={setUpdate} refresh={refresh} />
          :
          <UpdateMelding id={id} setUpdate={setUpdate} refresh={refresh} />
        }
      </div>
    </div>
  );

  // Voor de change kolommen in de tables op verschillende paginas. Nieuws alleen pencil, melding pencil of eye
  return (
    <div className="inhoud">
      {selectedId === id ?
        <FaEye className="update-icon" />
        : <FaPencilAlt className="update-icon" onClick={setSelectedId ? () => setSelectedId(id) : toggleItem} />
      }
      {item === 'news' &&
        <DeleteNewsItem id={id} refresh={refresh} />
      }
      {updateContainer}
    </div>
  );
};