import { useEffect, useState } from "react";
import "./ImagePopup.css";
import "./UpdateMelding.css";
import { RES_TYPES, useFetch } from "@chainless/chainless-react";
import { FaEye } from "react-icons/fa";

export const ApproveVerlof = ({ id, fetch, goedkeurder }) => {
  const { data } = useFetch(`/api/verlof/${id}`);
  const [update, setUpdate] = useState(false);
  const { res: putResRes, fetchData: putRes, error: putErr } = useFetch(`/api/verlof/${id}`, { method: 'PUT', resType: RES_TYPES.text, lateFetch: true });

  const toggleItem = () => setUpdate(s => !s);

  const formatDate = (datum) => {
    const date = new Date(datum * 1000);
    return date.toLocaleDateString('nl-NL');
  };

  const goedkeuren = () => {
    if (window.confirm("Deze aanvraag goedkeuren?")) {
      putRes(JSON.stringify({
        ...data,
        statusAanvraag: 'GOEDGEKEURD',
        datumGoedgekeurd: Math.floor(Date.now() / 1000),
        goedgekeurdDoor: goedkeurder
      }));
    }
  };

  const afwijzen = () => {
    if (window.confirm("Deze aanvraag afwijzen?")) {
      putRes(JSON.stringify({
        ...data,
        statusAanvraag: 'AFGEWEZEN',
        datumGoedgekeurd: Math.floor(Date.now() / 1000),
        goedgekeurdDoor: goedkeurder
      }));
    }
  };

  useEffect(() => {
    if (putResRes && putResRes.ok) {
      fetch();
      setUpdate(false);
    } else if (putErr) {
      alert('fout bij het versturen', putErr);
    }
  }, [putResRes, putErr, fetch]);

  const updateContainer = !update ? '' : (
    <div className="foto-container">
      <div className="update-content">
        <div className="updateField">
          {data &&
            <div className="update">
              <div className="verlof">
                <table>
                  <tr>
                    <td>Aanvrager</td>
                    <td>{data.aanvrager}</td>
                  </tr>
                  <tr>
                    <td>Data verlof</td>
                    <td>{formatDate(data.start)} t/m {formatDate(data.eind)}</td>
                  </tr>
                  <tr>
                    <td>Omschrijving</td>
                    <td>{data.omschrijving}</td>
                  </tr>
                  <tr>
                    <td>Aantal uren</td>
                    <td>{data.uren}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{data.statusAanvraag}</td>
                  </tr>
                </table>
              </div>
              {data.statusAanvraag === 'IN_BEHANDELING' ? (
                <>
                  <button onClick={() => goedkeuren()}>Goedkeuren</button>
                  <button className="verlofButton" onClick={() => afwijzen()}>Afwijzen</button>
                </>
              ) : (
                data.statusAanvraag === 'AFGEWEZEN' ? (
                  <button style={{ backgroundColor: 'red' }}>Afgewezen</button>
                ) : (
                  <button style={{ backgroundColor: 'green' }}>Goedgekeurd</button>
                )
              )}
              <button onClick={() => setUpdate(false)}>Terug</button>
            </div>
          }
        </div>
      </div>
    </div>
  );

  return (
    <>
      <FaEye className="update-icon" onClick={toggleItem} />
      {updateContainer}
    </>
  );
};