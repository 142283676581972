import { RES_TYPES, useFetch } from "@chainless/chainless-react";
import { useEffect, useState } from "react";
import "./UpdateMelding.css";

export const UpdateMelding = ({ id, setUpdate, refresh }) => {
  const { data } = useFetch(`/api/meldingen/${id}`);
  const { res: patchMeldingResRes, fetchData: patchMeldingRes, error: patchError, } = useFetch(`/api/meldingen/${id}`, { method: 'PATCH', resType: RES_TYPES.text, lateFetch: true });
  const [maatregelen, setMaatregelen] = useState();
  const [tijdsplanning, setTijdsplanning] = useState();
  const [voltooid, setVoltooid] = useState();
  const [toevoeging, setToevoeging] = useState();
  const [prioriteit, setPrioriteit] = useState();

  useEffect(() => {
    if (data) {
      setMaatregelen(data.genomenMaatregelen || '');
      setTijdsplanning(data.tijdsplanning ? new Date(data.tijdsplanning * 1000).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }) : null);
      setVoltooid(data.voltooidOp ? new Date(data.voltooidOp * 1000).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }) : null);
      setToevoeging(data.toevoeging || undefined);
      setPrioriteit(data.prioriteit);
    }
  }, [data]);

  const reverseTijd = (tijd) => {
    if (tijd) {
      const reversed = tijd.split('-').reverse().join('-');
      return Math.floor(new Date(reversed).getTime() / 1000);
    } else {
      return null;
    }
  };

  const confirmUpdate = () => {
    if (window.confirm('Weet u het zeker?')) {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    const nieuweTijdsplanning = reverseTijd(tijdsplanning);
    const nieuweVoltooid = reverseTijd(voltooid);

    patchMeldingRes(JSON.stringify({
      genomenMaatregelen: maatregelen,
      toevoeging: toevoeging,
      tijdsplanning: nieuweTijdsplanning,
      voltooidOp: nieuweVoltooid,
      prioriteit: prioriteit !== null ? prioriteit : 'PRIO_3'
    }));
  };

  useEffect(() => {
    if (patchMeldingResRes && patchMeldingResRes.ok) {
      refresh();
      setUpdate(false);
    } else if (patchError) {
      alert('Fout bij het updaten van een nieuws item', patchError);
    }
  }, [patchMeldingResRes, patchError, setUpdate, refresh]);

  if (data) return (
    <>
      <div className="item" style={{ marginTop: 50 }}>
        <p className="label">Tijdsplanning</p>
        <input type="text" id="datum" name="datum" placeholder="DD-MM-JJJJ" maxLength={10} value={tijdsplanning} onChange={e => setTijdsplanning(e.target.value)} />
      </div>
      <div className="item">
        <p className="label">Voltooid op</p>
        <input type="text" id="datum" name="datum" placeholder="DD-MM-JJJJ" maxLength={10} value={voltooid} onChange={e => setVoltooid(e.target.value)} />
      </div>
      <div className="item">
        <p className="label">Genomen maatregelen</p>
        <input value={maatregelen} onChange={e => setMaatregelen(e.target.value)} />
      </div>
      <div className="item">
        <p className="label">Toevoeging</p>
        <input type='text' value={toevoeging} onChange={e => setToevoeging(e.target.value)} />
      </div>
      <div className="item">
        <p className="label">Prioriteit</p>
        <select name="prio" id="prio" onChange={(e) => setPrioriteit(e.target.value)} defaultValue={data.prioriteit}>
          <option value="PRIO_3">PRIO 3</option>
          <option value="PRIO_2">PRIO 2</option>
          <option value="PRIO_1">PRIO 1</option>
        </select>
      </div>
      <button onClick={() => confirmUpdate()}>Update</button>
      <button onClick={() => setUpdate(false)}>Terug</button>
    </>
  );
};