import { ChnSortSearchTable, useFetch } from '@chainless/chainless-react';
import { useEffect, useState } from 'react';
import { CreateNewsItem } from '../components/CreateNewsItem';
import { ImagePopup } from '../components/ImagePopup';
import { Responses } from '../components/Responses';
import { UpdateItem } from '../components/UpdateItem';
import './pages.css';

export const NieuwsPage = () => {
  const [create, setCreate] = useState(false);
  const [page, setPage] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const { data: news, error, loading, fetchData } = useFetch(`/api/news?page=${page}`);

  const refreshAll = () => {
    setNewsList([]);
    setPage(0);
    fetchData();
  };

  const loadMoreNews = async () => {
    setPage(page + 1);
    await fetchData();
  };

  useEffect(() => {
    if (news && news.length > 0) {
      const uniqueNews = news.filter((newNews) => !newsList.find((existingNews) => existingNews.id === newNews.id));
      setNewsList((prevNews) => [...prevNews, ...uniqueNews]);
    }
  }, [news]);

  const headers = [
    { key: 'timestamp', name: 'Datum/tijd', valueMapper: (timestamp) => new Date(timestamp * 1000).toLocaleString('nl-NL'), search: false },
    { key: 'title', name: 'Title' },
    { key: 'author', name: 'Author' },
    { key: 'foto', name: 'Foto', valueMapper: (f) => <ImagePopup src={`/api/images/${f}`} />, search: false },
    { key: 'responses', name: 'Responses', valueMapper: (r) => <Responses r={r} />, search: false },
    { key: 'id', name: 'Change', valueMapper: (id) => <UpdateItem id={id} refresh={refreshAll} item={'news'} />, search: false }
  ];

  return (
    <div className='content'>
      <h1>Laatste Nieuws</h1>
      {!create ?
        <>
          <button onClick={() => setCreate(!create)}>Nieuwsbericht maken</button>
          {loading && <p>Nieuws ophalen...</p>}
          {error && <p>Error bij ophalen nieuws: {error}</p>}
          {news && <div className='table'><ChnSortSearchTable headers={headers} data={newsList} /><button onClick={() => loadMoreNews()}>Meer Nieuws Laden</button></div>}
        </>
        :
        <CreateNewsItem setCreate={setCreate} refresh={refreshAll} />
      }
    </div>
  );
};
