import { useState } from "react";
import { FaImage } from "react-icons/fa";
import { useFetch } from "@chainless/chainless-react";
import "./ImagePopup.css";

export const ImagePopup = ({src}) => {
  const [fotoShown, setFotoShown] = useState(false);

  const toggleFoto = () => setFotoShown(s => !s);
  
  const { data, error, loading } = useFetch(src);

  const fotoContainer = !fotoShown ? '' : (
    <div className="foto-container" onClick={toggleFoto}>
      { loading && <p> bezig met ophalen van foto </p> }
      { error && <p> Geen foto </p> }
      { data && <img alt={data.alt} src={data.image}></img> }
    </div>
  );

  if (src) {
    return (
      <>
        <FaImage className="update-icon" onClick={toggleFoto} />
        { fotoContainer }
      </>
    );
  }
};