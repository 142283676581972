import { RES_TYPES, useFetch } from "@chainless/chainless-react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const CreateNewsItem = ({ setCreate, refresh, id, setUpdate }) => {
  const { data } = useFetch(id ? `api/news/${id}` : null);
  const { res: postNewsResRes, fetchData: postNewsRes, error: postNewsError } = useFetch('api/news', { method: 'POST', resType: RES_TYPES.text, lateFetch: true });
  const { res: putNewsResRes, fetchData: putNewsRes, error: putError, } = useFetch(`api/news/${id}`, { method: 'PUT', resType: RES_TYPES.text, lateFetch: true });
  const [image, setImage] = useState();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState(EditorState.createEmpty());
  const { data: user } = useFetch('/api/graph/user');
  const { data: selectedImage } = useFetch(data ? `/api/images/${data.foto}` : null);
  const [newsCreator, setNewsCreator] = useState();

  useEffect(() => {
    if (user) {
      setNewsCreator(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setBody(EditorState.createWithContent(convertFromRaw(JSON.parse(data.body))));
    }
  }, [data]);

  const sendNewsItem = () => {
    if (title && body && image) {
      postNewsRes(JSON.stringify({
        title: title,
        body: JSON.stringify(convertToRaw(body.getCurrentContent())),
        foto: image,
        author: newsCreator,
        timestamp: Math.floor(Date.now() / 1000)
      }));
    } else {
      alert('Vul alle velden in');
    }
  };

  useEffect(() => {
    if (selectedImage && selectedImage.image) {
      setImage(selectedImage.image);
    }
  }, [selectedImage]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
  };

  const handleUpdate = () => {
    if (title && body && image) {
      putNewsRes(JSON.stringify({
        title: title,
        body: JSON.stringify(convertToRaw(body.getCurrentContent())),
        foto: image,
        author: newsCreator,
        timestamp: Math.floor(Date.now() / 1000)
      }));
    } else {
      alert('Vul alle velden in');
    }
  };

  useEffect(() => {
    if (putNewsResRes && putNewsResRes.ok) {
      refresh();
      setUpdate(false);
    } else if (putError) {
      alert('Fout bij het updaten van een nieuws item', putError);
    }
  }, [putNewsResRes, putError, refresh, setUpdate]);

  useEffect(() => {
    if (postNewsResRes && postNewsResRes.ok) {
      refresh();
      setCreate(false);
    } else if (postNewsError) {
      alert('Fout bij het aanmaken van een nieuws item', postNewsError);
    }
  }, [postNewsError, postNewsResRes, refresh, setCreate]);

  return (
    <div className="create">
      <input className="title" placeholder="Vul een titel in" type='text' value={title} onChange={e => setTitle(e.target.value)} />
      <Editor editorState={body} editorClassName="editor" onEditorStateChange={setBody} />
      <div className="imageSelect">
        <p>Foto uploaden</p>
        <input data-testid="Foto-uploaden" className="foto" type='file' accept='image/*' onChange={handleImageChange} />
      </div>
      <>
        {image &&
          <img src={image} alt="selected" className="uploadImage" data-testid="img"/>
        }
        <button onClick={() => data ? handleUpdate() : sendNewsItem()}>{data ? 'Update' : 'Plaatsen'}</button>
        <button onClick={() => data ? setUpdate(false) : setCreate(false)}>Terug</button>
      </>
    </div>
  );
};