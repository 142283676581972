import { useState } from "react";
import { KernwaardenDetails } from "../components/KernwaardenDetails";
import { useFetch } from "@chainless/chainless-react";
import { Documenten } from "../components/Documenten";
import { Telefoonnummers } from "../components/Telefoonnummers";

export const VGMPage = () => {
  const { data: kernwaarden, fetchData: fetchKernwaarden } = useFetch('/api/vgm/kernwaarden');
  const { data: documenten, fetchData: docFetch } = useFetch('/api/vgm/documenten');
  const { data: telefoonnummers, fetchData: fetchTelefoonnummers } = useFetch('/api/vgm/telefoonnummers');
  const [isActive, setIsActive] = useState(1);

  return (
    <div className="content">
      <h1>VGM</h1>
      <div className="vgm-inhoud">
        <div className="lijst">
          <h2>VGM lijst</h2>
          <button data-testid="Documenten" className={isActive === 1 ? 'lijst-item active' : 'lijst-item'} onClick={() => setIsActive(1)}>Documenten</button>
          <button data-testid="Telefoonnummers" className={isActive === 2 ? 'lijst-item active' : 'lijst-item'} onClick={() => setIsActive(2)}>Telefoonnummers</button>
          <button data-testid="Kernwaarden" className={isActive === 3 ? 'lijst-item active' : 'lijst-item'} onClick={() => setIsActive(3)}>Kernwaarden</button>
        </div>
        <div className="info">
          {isActive === 1 &&
            <Documenten documenten={documenten} refresh={docFetch} />
          }
          {isActive === 2 &&
            <Telefoonnummers telefoonnummers={telefoonnummers} refresh={fetchTelefoonnummers} />
          }
          {isActive === 3 &&
            <KernwaardenDetails kernwaarden={kernwaarden} refresh={fetchKernwaarden} />
          }
        </div>
      </div>
    </div>
  );
};