import { RES_TYPES, useFetch } from "@chainless/chainless-react";
import { useEffect } from "react";
import { FaTrash } from "react-icons/fa";

export const DeleteVGMItem = ({ id, refresh, choice }) => {
  const { data: kernwaardenData, error, fetchData: deleteKernwaarde } = useFetch(`/api/vgm/kernwaarden/${id}`, { method: 'DELETE', resType: RES_TYPES.text, lateFetch: true });
  const { data: telData, error: telErr, fetchData: deleteTel } = useFetch(`/api/vgm/telefoonnummers/${id}`, { method: 'DELETE', resType: RES_TYPES.text, lateFetch: true });
  const { data: docData, error: docErr, fetchData: deleteDoc } = useFetch(`/api/vgm/documenten/${id}`, { method: 'DELETE', resType: RES_TYPES.text, lateFetch: true });

  const handleDelete = () => {
    const userConfirmation = window.confirm('Weet u het zeker?');
    if (userConfirmation && choice === "documenten") {
      deleteDoc();
    } else if (userConfirmation && choice === "telefoonnummers") {
      deleteTel();
    } else if (userConfirmation && choice === "kernwaarden") {
      deleteKernwaarde();
    }
  };

  useEffect(() => {
    if (kernwaardenData || docData || telData) {
      refresh();
    }
  }, [kernwaardenData, docData, telData])

  useEffect(() => {
    if (error || docErr || telErr) {
      alert('Fout bij het verwijderen van een item');
    }
  }, [docErr, error, telErr]);

  if (id) {
    return (
      <>
        <FaTrash onClick={handleDelete} className="update-icon" />
      </>
    );
  }
};