import Plot from "react-plotly.js";

export const BarChart = ({ selectedData, total, barmode, title }) => {

  return (
    <Plot
      data={selectedData}
      config={{ 'displayModeBar': false }}
      layout={{
        showlegend: true,
        barmode: barmode,
        barcornerradius: 8,
        width: 700, height: 280, title: title,
        font: {
          color: '#485074',
          size: 14,
          weight: 'bold'
        },
        dragmode: false,
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        xaxis: {
          title: `Totaal aantal meldingen dit jaar: ${total}`,
          titlefont: {
            size: 18,
            family: 'Calibri',
          },
          tickfont: {
            size: 14,
            family: 'Calibri'
          },
          dtick: 1
        },
        yaxis: {
          title: 'Meldingen',
          tickmode: 'linear',
          tick0: 0,
          dtick: 1,
          tickformat: 'd',
          titlefont: {
            size: 18,
            family: 'Calibri'
          },
          tickfont: {
            size: 14,
            family: 'Calibri'
          }
        },
        hoverlabel: {
          bgcolor: 'black',
          font: {
            color: 'white',
          }
        }
      }}
    />
  );
};