import { RES_TYPES, useFetch } from "@chainless/chainless-react";
import { useEffect, useState } from "react";
import { DeleteVGMItem } from "./DeleteVGMItem";

export const Documenten = ({ documenten, refresh }) => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const { res: postResRes, fetchData, error: postErr } = useFetch(`/api/vgm/documenten`, { method: 'POST', resType: RES_TYPES.text, lateFetch: true });

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const extension = fileName.split('.').pop();
      if (extension.toLowerCase() !== 'pdf') {
        alert('Alleen PDF-bestanden zijn toegestaan.');
        return;
      }
      setName(fileName.replace(".pdf", ""));
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setFile(reader.result);
      };
    }
  };

  const uploadFile = () => {
    if (file && !documenten?.some(doc => doc.name === name)) {
      fetchData(JSON.stringify({
        name: name,
        document: file
      }));
    }
    else {
      alert('File uploaden niet gelukt, het bestand moet pdf zijn en mag niet een reeds gebruikte naam hebben');
    }
  };

  useEffect(() => {
    if (postResRes && postResRes.ok) {
      setFile(null);
      setName('');
      refresh();
    } else if (postErr) {
      alert('Fout bij het updaten van een nieuws item', postErr);
    }
  }, [postResRes, postErr, refresh]);


  return (
    <div className="vgmDetails">
      <h2>Documenten</h2>
      <div className="documenten-lijst">
        {documenten && documenten.map(doc => (
          <div className="detailsLijstItem" key={doc.id}>
            <p>{doc.document}</p>
            <DeleteVGMItem id={doc.id} refresh={refresh} choice={"documenten"} />
          </div>
        ))}
      </div>
      <div className="documenten-lijst">
        <h2>PDF invoeren</h2>
        <input type="file" accept=".pdf" className="doc-input" data-testid="file-input" onChange={handleFileChange} />
        <button onClick={uploadFile}>Uploaden</button>
      </div>
    </div>
  );
};